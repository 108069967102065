import React, { useEffect, useState, createRef, useRef } from 'react';
import  './styles/FullStackZack.scss';
import { createStyles, Theme, makeStyles } from '@material-ui/core';


    const useStyles = makeStyles((theme: Theme) => createStyles({
        fullStackZack: {
            backgroundColor: '#282c34',
            color: '#61dafb',
            fontFamily: 'sans-serif',
            fontSize: '20px',
            letterSpacing: '3px',
            fontWeight: 600
        }
    }));



export default function FullStackZack() {
    const classes = useStyles();
    
    function Messenger(el: any){
        'use strict';
        let m: any = {}; 
        
        m.init = function(){
          m.codeletters = "&#*+%?£@§$";
          m.message = 0;
          m.current_length = 0;
          m.fadeBuffer = false;
          m.messages = [
            'FullStackZack'
          ];
          
          setTimeout(m.animateIn, 100);
        };
        
        m.generateRandomString = function(length: number){
          var random_text = '';
          while(random_text.length < length){
            random_text += m.codeletters.charAt(Math.floor(Math.random()*m.codeletters.length));
          } 
          
          return random_text;
        };
        
        m.animateIn = function(){
          if(m.current_length < m.messages[m.message].length){
            m.current_length = m.current_length + 2;
            if(m.current_length > m.messages[m.message].length) {
              m.current_length = m.messages[m.message].length;
            }
            
            var message = m.generateRandomString(m.current_length);
            el.innerHTML = message;
            
            setTimeout(m.animateIn, 20);
          } else { 
            setTimeout(m.animateFadeBuffer, 20);
          }
        };
        
        m.animateFadeBuffer = function(){
          if(m.fadeBuffer === false){
            m.fadeBuffer = [];
            for(var i = 0; i < m.messages[m.message].length; i++){
              m.fadeBuffer.push({c: (Math.floor(Math.random()*12))+1, l: m.messages[m.message].charAt(i)});
            }
          }
          
          var do_cycles = false;
          var message = ''; 
          
          for(var i = 0; i < m.fadeBuffer.length; i++){
            var fader = m.fadeBuffer[i];
            if(fader.c > 0){
              do_cycles = true;
              fader.c--;
              message += m.codeletters.charAt(Math.floor(Math.random()*m.codeletters.length));
            } else {
              message += fader.l;
            }
          }
          
          el.innerHTML = message;
          
          if(do_cycles === true){
            setTimeout(m.animateFadeBuffer, 50);
          }
        };
        
        m.cycleText = function(){
          m.message = m.message + 1;
          if(m.message >= m.messages.length){
            m.message = 0;
          }
          
          m.current_length = 0;
          m.fadeBuffer = false;
          el.innerHTML = "";
          
          setTimeout(m.animateIn, 200);
        };
        
        m.init();
        console.log('hit');
      }

    useEffect(() => {
        Messenger(document.getElementById("FullStackZack"));
        console.log('test');
    }, []);

    return (
        <div id="FullStackZack" className={classes.fullStackZack}></div>
    );
}